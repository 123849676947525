$drawer-width: 275px;

#menu-button {
    cursor: pointer;
}

#menu-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: -100%;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.54);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity;
    transform: translateZ(0px);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1100;
    pointer-events: auto;
}

#menu-drawer {
    background-color: lighten(#343A40, 3);
    transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 10px, rgba(0, 0, 0, 0.23) 0 3px 10px;
    border-radius: 0;
    height: 100%;
    width: $drawer-width;
    position: fixed;
    z-index: 1200;
    right: 0;
    top: 0;
    transform: translate($drawer-width + 10px, 0px);
    overflow: auto;

    .nav-link {
        padding: 1rem 1.5rem;
        color: rgba(255, 255, 255, 0.5);

        &:hover {
            color: rgba(255, 255, 255, 0.75)
        }
    }

    .title {
        height: 70px;
        line-height: 70px;
        color: hsla(0, 0%, 100%, .5);
        font-family: "Brandon Grotesque Light", sans-serif;
        font-size: 1.5rem;
        text-transform: uppercase;
    }
}
