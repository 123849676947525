body {
    padding-top: 66px;
}

.navbar-brand {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    svg {
        margin-top: -6px;
    }
}
